@media screen and (max-width: 768px) {
    .swiper {
    margin: 20px auto;
    width: 280px;
    height: 600px;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px)  {.swiper {
    margin: 20px auto;
    width: 300px;
    height: 600px;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
}
@media screen and (min-width: 1025px) {.swiper {
    margin: 20px auto;
    width: 500px;
    height: 700px;
  }
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
}