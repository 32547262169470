@media screen and (max-width: 768px) {.swiper {
    width: 280px;
    height: 600px;
    padding: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 280px;
    height: 600px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px)  {.swiper {
    width: 400px;
    height: 600px;
    padding: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 600px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 1025px) {.swiper {
    width: 500px;
    height: 700px;
    padding: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 500px;
    height: 700px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
}